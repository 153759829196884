<template lang="pug">
div(class='ServiceAction', :class='{ HasPrice: hasPrice }')
	div(v-if='hasPrice', class='ServiceAction--Price', :class='{ NoCredit: !credit }') {{ $f.formatPrice(price) }}
	div(v-if='hasCredit', class='ServiceAction--Credit')
		span(class='Price') {{ $f.formatPrice(price / creditPeriod) }}
		span(class='Divider') x
		span(class='Period') {{ creditPeriod }} {{ greaterOrEquals('mobile') ? $f.pluralize(creditPeriod, ['платёж', 'платежа', 'платежей']) : '' }}

	div(
		v-if='form',
		class='ServiceAction--Action',
		:class='{ Consultation: !hasPrice }',
		@click='getServiceModal'
	) {{ button }}
</template>

<script lang="ts" setup>
import { Form } from '~/models/form';

const { greaterOrEquals } = usePlatform();
const { modalState, modalShow } = useModal();

/**
 * Define props
 */
const {
	form = Form.modelState,
	price = 0,
	credit = false,
	creditPeriod = 1,
	button = 'Оформить',
} = defineProps<{
	form: Form.Model | null;
	price: number;
	credit?: boolean;
	creditPeriod?: number;
	button?: string | null;
}>();

const hasPrice = computed(() => price > 0);
const hasCredit = computed(() => hasPrice.value && credit);

/**
 * Get service modal
 */
const getServiceModal = () => {
	if (!form) return;

	modalState.value = {
		header: form.header,
		description: form.description,
		source: form.source,
		subject: form.subject,
		form: form.fields,
		target: form.target,
	};

	modalShow();
};
</script>

<style lang="less" scoped>
.ServiceAction {
	.box(grid; auto; auto; none; 0; 0.75rem 1fr auto 0.75rem; 1fr; center; start; column);
	.border-radius(@BorderRadiusFull);

	&.HasPrice {
		padding: 0 0 0 1rem;
		border: 0.0625rem fade(@ColorBase, 50%) solid;
		.box(grid; 100%; auto; none; 0.3125rem; 0.3125rem 1fr auto 0.3125rem; 1fr auto; center; start; column);
	}

	&--Price {
		.grid(2 3; 1 2);
		.text(@ColorBase; 1em 1rem @bold);
		&.NoCredit {
			.grid(2 4; 1 2);
		}
	}

	&--Credit {
		.grid(3 4; 1 2);
		.box(grid; auto; auto; none; 0.25rem; 1fr; auto auto auto; center; start; column);
		& > .Price {
			.text(@ColorBase; 1em 0.625rem @black);
		}
		& > .Divider {
			.text(fade(@ColorBase, 70%); 1em 0.625rem @regular);
		}
		& > .Period {
			.text(@ColorBase; 1em 0.625rem @regular);
		}
	}

	&--Action {
		padding: 0 1rem 0 1rem;
		.grid(1 5; 2 3);
		.background(@ColorBase);
		.text(@ColorBg; 1em 0.75rem @regular);
		.flexbox(center; center; center; nowrap row);
		.border-radius(@BorderRadiusFull);
		.calc(width; ~'100% + 0.125rem');
		.calc(height; ~'100% + 0.25rem');
		.transition(all; 250ms; 'sine');
		.cursor-pointer();
		&:hover {
			.background(@ColorPrimary);
			.text(@ColorWhite);
		}

		&.Consultation {
			padding: 0.5rem 1rem 0.5rem 1rem;
		}
	}

	/* >= 500px */
	@media all and (min-width: @mobile) {
		max-width: 20rem;
		&.HasPrice {
			padding: 0 0 0 1.875rem;
			border: 0.125rem fade(@ColorBase, 50%) solid;
			.box(grid; auto; auto; none; 0.375rem 1.25rem; 0.75rem 1fr auto 0.75rem; 1fr auto; center; start; column);
		}
		&--Price {
			font-size: 1.125rem;
		}
		&--Credit {
			& > .Price {
				font-size: 0.75rem;
			}
			& > .Divider {
				font-size: 0.75rem;
			}
			& > .Period {
				font-size: 0.75rem;
			}
		}
		&--Action {
			padding: 0 2rem 0 2rem;
			font-size: 0.875rem;
			&.Consultation {
				padding: 1rem 1.5rem 1rem 1.5rem;
			}
		}
	}

	/* >= 1300px */
	@media all and (min-width: @large-screen-small) {
		max-width: unset;
		&.HasPrice {
			padding: 0 0 0 2.5rem;
		}
		&--Price {
			font-size: 2rem;
		}
		&--Credit {
			.grid(3 4; 1 2);
			.box(grid; auto; auto; none; 0.25rem; 1fr; auto auto auto; center; start; column);
			& > .Price {
				font-size: 0.875rem;
			}
			& > .Divider {
				font-size: 0.875rem;
			}
			& > .Period {
				font-size: 0.875rem;
			}
		}
		&--Action {
			padding: 0 3rem 0 3rem;
			font-size: 1rem;
			&.Consultation {
				padding: 1rem 2rem 1rem 2rem;
			}
		}
	}
}
</style>
